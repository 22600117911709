<template>
  <v-container
    id="login"
    class="fill-height justify-center"
    tag="section"
    @keyup.enter="login"
  >
    <base-material-card
      color="white"
      light
      max-width="100%"
      width="300"
      class="px-5 py-3"
    >
      <template v-slot:heading>
        <v-img
          :src="require(`@/assets/logo.png`)"
          class="mx-auto"
          width="200"
        />
      </template>

      <!-- Login with email and password -->
      <v-card-text
        v-if="panelName === 'login'"
        class="text-center"
      >
        <v-form
          ref="loginForm"
          lazy-validation
          @submit.prevent="login"
        >
          <v-text-field
            v-model="email"
            type="email"
            color="secondary"
            label="Email *"
            prepend-icon="mdi-email"
            :rules="[rules.required, rules.email]"
          />

          <v-text-field
            v-model="password"
            class="mb-8"
            color="secondary"
            label="Password *"
            prepend-icon="mdi-lock-outline"
            type="password"
            :rules="[rules.required]"
          />

          <pages-btn
            large
            color=""
            type="submit"
            depressed
            class="v-btn--text success--text"
          >
            Let's Go
          </pages-btn>
        </v-form>
      </v-card-text>

      <!-- Select verification method -->
      <v-card-text
        v-else-if="panelName === 'options'"
        class="text-center"
      >
        <h4 class="mb-5">
          Select verification method
        </h4>
        <v-radio-group v-model="verificationMethod">
          <v-radio
            class="mb-5"
            color="secondary"
            label="Google Authenticator"
            value="ga"
          />
          <v-radio
            class="mb-5"
            color="secondary"
            label="Send Email"
            value="email"
          />
        </v-radio-group>

        <pages-btn
          large
          color=""
          depressed
          class="v-btn--text success--text"
          :loading="sendingEmail"
          @click="selectVerification"
        >
          Let's Go
        </pages-btn>
      </v-card-text>

      <!-- QR code svg -->
      <v-card-text
        v-else-if="panelName === 'svg'"
        class="text-center"
      >
        <h5 class="mb-5">
          Scan the QR code below or use the token
          <h3 class="ma-3">
            {{ gaToken }}
          </h3>
          to register in your authenticator app.
        </h5>

        <img
          :src="`data:image/svg+xml;base64,${svg}`"
          alt="qr-code"
        >

        <div>
          <pages-btn
            large
            color=""
            depressed
            class="v-btn--text success--text ma-auto mt-5"
            @click="panelName = 'input'"
          >
            Let's Go
          </pages-btn>
        </div>
      </v-card-text>

      <!-- Input auth code -->
      <v-card-text
        v-else-if="panelName === 'input'"
        class="text-center"
      >
        <v-form
          ref="verifyAuthCodeForm"
          lazy-validation
          @submit.prevent="verifyAuthCode"
        >
          <v-text-field
            v-model="authCode"
            class="mb-8"
            color="secondary"
            label="Auth Code *"
            prepend-icon="mdi-lock-outline"
            :rules="[rules.required]"
          />
          <pages-btn
            large
            color=""
            type="submit"
            depressed
            class="v-btn--text success--text"
          >
            Let's Go
          </pages-btn>
        </v-form>
      </v-card-text>
    </base-material-card>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import cookies from 'js-cookie'
  import { mapActions } from 'vuex'

  export default {
    name: 'PagesLogin',

    components: {
      PagesBtn: () => import('./components/Btn'),
    },

    data: () => ({
      email: '',
      password: '',
      rememberMe: false,
      loginFailed: false,
      rules: {
        required: value => !!value || 'This field is required.',
        email: v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      },
      panelName: 'login',
      verificationMethod: 'ga',
      gaToken: null,
      authCode: '',
      svg: null,
      sendingEmail: false,
    }),

    methods: {
      ...mapActions({
        showSnackBar: 'showSnackBar',
      }),

      async login () {
        if (this.$refs.loginForm.validate()) {
          try {
            const response = await axios.post('auth/login', {
              email: this.email,
              password: this.password,
              unlocked: cookies.get('unlocked') ? 1 : 0,
            })
            if (response.data.success) {
              // Need to consider token expiry here
              if (response.data.token) {
                //  this.$store.dispatch('setToken', { token: response.data.token })
                //  this.$router.push('/')
                this.panelName = 'input'
              } else {
                this.panelName = 'options'
                this.gaToken = response.data.two_factor_secret
              }
            } else {
              this.showSnackBar({ text: 'Login Failed', color: 'error' })
            }
          } catch (error) {
            this.showSnackBar({ text: 'Login Failed', color: 'error' })
          }
        }
      },
      async selectVerification () {
        try {
          if (this.verificationMethod === 'email') {
            this.sendingEmail = true
            //  const sendEmailResponse = await axios.post('auth/send-verify-email', {
            const sendEmailResponse = await axios.post('auth/send-email-otp', {
              email: this.email,
              password: this.password,
            })
            if (sendEmailResponse.data.success) {
              this.panelName = 'input'
            } else {
              this.showSnackBar({ text: 'Email not sent', color: 'error' })
            }
            this.sendingEmail = false
          } else {
            if (this.gaToken) {
              // GA app registered already and show code input
              this.panelName = 'input'
            } else {
              const checkQrRegistration = await axios.get('auth/check-qr-registration', {})
              if (checkQrRegistration.data.success === true) {
                this.panelName = 'input'
              } else {
                const check2FAPasswordConfirm = await axios.post('user/confirm-password', {
                  email: this.email,
                  password: this.password,
                })
                if (check2FAPasswordConfirm) {
                  const check2FA = await axios.post('user/two-factor-authentication', {
                    email: this.email,
                    password: this.password,
                  })
                  if (check2FA) {
                    const twoFactorQrCodeResponse = await axios.get('user/two-factor-qr-code', {})
                    if (twoFactorQrCodeResponse) {
                      this.panelName = 'svg'
                      //  this.gaToken = qrCodeResponse.data.two_factor_secret
                      //  const xmlSvg = new DOMParser().parseFromString(qrCodeResponse.data.svg, 'text/html')
                      //  const svgDocument = xmlSvg.body.getElementsByTagName('svg')[0]
                      //  const svg = new XMLSerializer().serializeToString(svgDocument)
                      this.svg = Buffer.from(twoFactorQrCodeResponse.data.svg).toString('base64')
                    }
                  }
                }
              }
            }
          }
        } catch (error) {
          this.showSnackBar({ text: 'Verification Failed', color: 'error' })
        }
      },

      async verifyAuthCode () {
        try {
          //  const url = this.verificationMethod === 'ga' ? 'auth/verify-qr-code' : 'auth/two-factor-email-login'
          //  const url = 'user/confirmed-two-factor-authentication'
          const url = this.verificationMethod === 'ga' ? 'user/confirmed-two-factor-authentication' : 'auth/verify-email-otp'
          const response = await axios.post(url, {
            email: this.email,
            password: this.password,
            code: this.authCode,
          })
          if (this.verificationMethod === 'ga') {
            // I created this because the response is empty
            const getToken = await axios.get('auth/get-token', {})
            if (getToken.data.success) {
              this.$store.dispatch('setToken', { token: getToken.data.token })
              this.$router.push('/')
            } else {
              this.showSnackBar({ text: 'Verification Failed', color: 'error' })
            }
          } else {
            if (response.data.success) {
              this.$store.dispatch('setToken', { token: response.data.token })
              this.$router.push('/')
            } else {
              this.showSnackBar({ text: 'Verification Failed', color: 'error' })
            }
          }
        } catch (error) {
          this.showSnackBar({ text: 'Verification Failed', color: 'error' })
        }
      },
    },
  }
</script>
